import MuiButton from '@mui/material/Button';
import PropTypes from 'prop-types';
import * as color from '../constants/colors';

const styles = {
  button: {
    textTransform: 'none',
  },
  contained: {
    backgroundColor: color.inverseSurfaceLight,
    color: color.inverseOnSurfaceLight,
    letterSpacing: '1.25px',
    py: 1.125,
    '&:hover': {
      backgroundColor: color.neutral35,
      '@media (hover: none)': {
        backgroundColor: color.neutral35,
      },
    },
    // Reset on touch devices, it doesn't add specificity
    '@media (hover: none)': {
      backgroundColor: color.neutral35,
    },
    '&.Mui-focusVisible': {
      outline: `${color.tertiaryLight} auto 1px`,
      outlineOffset: '1px',
    },
  },
};

export default function Button(props) {
  const { children, sx, variant, ...other } = props;
  return (
    <MuiButton
      disableFocusRipple
      sx={[
        styles.button,
        variant === 'contained' && styles.contained,
        ...(sx && Array.isArray(sx) ? sx : []),
        ...(sx && !Array.isArray(sx) ? [sx] : []),
      ]}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {children}
    </MuiButton>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
  variant: PropTypes.oneOfType([PropTypes.oneOf(['contained']), PropTypes.string]),
};

Button.defaultProps = {
  sx: null,
  variant: 'contained',
};
