import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';

const firebaseConfig = {
  apiKey: 'AIzaSyC9EgbhpuzjEZKa8KOU2_iJgxtQ8iOQmCQ',
  authDomain: 'ison-website.firebaseapp.com',
  projectId: 'ison-website',
  storageBucket: 'ison-website.appspot.com',
  messagingSenderId: '504891146195',
  appId: '1:504891146195:web:d1b16feafa10ef2d807926',
  measurementId: 'G-G019FC26LH',
};

const app = initializeApp(firebaseConfig);
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LfSOyQfAAAAADGMYUsrAWKzejiexefTXYmTmsS2'),
  isTokenAutoRefreshEnabled: true,
});
const auth = getAuth(app);
const functions = getFunctions(app, 'northamerica-northeast1');

const requestConsultHandle = httpsCallable(functions, 'requestConsult');

export default function requestConsult(email, name) {
  signInAnonymously(auth).then(() => requestConsultHandle({ email, name }));
}
