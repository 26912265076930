import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import * as color from '../constants/colors';
import ArrowRightIcon from './ArrowRightIcon';

const styles = {
  button: {
    backgroundColor: color.neutral40,
    border: `2px solid ${color.neutral95}`,
    color: color.neutral95,
    p: 0,
    position: 'relative',
    textTransform: 'none',
    '&.Mui-focusVisible': {
      outline: '-webkit-focus-ring-color auto 1px',
      outlineOffset: '1px',
    },
    '& svg': {
      fill: color.neutral95,
    },
    '&:hover': {
      backgroundColor: color.neutral35,
      border: `2px solid ${color.neutral100}`,
      color: color.neutral100,
      '@media (hover: hover)': {
        '& > .MuiBox-root': {
          '& > .MuiBox-root': {
            right: 10,
          },
        },
      },
    },
    '&:active': {
      '& > .MuiBox-root': {
        '& > .MuiBox-root': {
          right: 10,
        },
      },
    },
  },
  box: {
    alignItems: 'center',
    display: 'flex',
    pr: 5,
    pl: 2.5,
  },
  divider: {
    borderColor: color.neutral95,
    borderRightWidth: '2px',
  },
  logo: {
    height: '1.5rem',
    width: '1.5rem',
    m: '0.5rem',
  },
  arrowBox: {
    height: '0.8333rem',
    width: '0.9524rem',
    position: 'absolute',
    right: 20,
    transitionDelay: '0s',
    transitionDuration: { xs: '0.2s', md: '0.4s' },
    transitionProperty: 'right',
    transitionTimingFunction: 'ease',
  },
  arrow: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    right: 0,
  },
};

export default function LogoButton(props) {
  const { children, onClick, open } = props;
  return (
    <Button onClick={onClick} size="large" sx={styles.button} variant="outlined">
      <Box sx={styles.logo}>
        <svg viewBox="0 0 190.5 190.5" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <clipPath id="a">
              <path
                className="powerclip"
                d="M-4.995-4.996h200.5v200.5h-200.5zm159.9 52.626v.003H90.733c-7.98 0-14.653 2.466-20.016 7.398-5.755 5.284-8.633 12.333-8.633 21.138 0 8.718 2.876 15.72 8.633 21.005 5.363 4.932 12.036 7.398 20.016 7.398h36.01c2.355 0 4.416.441 6.18 1.32 2.356 1.233 3.533 3.037 3.533 5.416 0 2.291-1.177 4.096-3.532 5.416-1.831 1.055-3.892 1.585-6.18 1.585H76.114L61.986 142.88h64.76c8.632 0 15.763-2.776 21.388-8.322 6.02-5.813 9.028-13.43 9.028-22.855 0-9.512-2.98-17.262-8.93-23.252-5.755-5.635-12.917-8.456-21.487-8.456h-36.01c-2.683 0-4.022-1.275-4.022-3.83 0-2.554 1.34-3.832 4.022-3.832h50.041zm-99.687 95.25V47.63H33.337v95.25z"
              />
            </clipPath>
          </defs>
          <path
            className="st0"
            d="M157.16 190.5H33.34C14.928 190.5.002 175.575.002 157.162V33.342C.002 14.93 14.927.004 33.34.004h123.82c18.412 0 33.337 14.925 33.337 33.338v123.82c0 18.412-14.925 33.338-33.337 33.338z"
            clipPath="url(#a)"
          />
        </svg>
      </Box>
      <Divider flexItem orientation="vertical" sx={styles.divider} />
      <Box sx={styles.box}>
        {children}
        <Box id="arrow" style={open ? { right: 10 } : {}} sx={styles.arrowBox}>
          <ArrowRightIcon sx={styles.arrow} />
        </Box>
      </Box>
    </Button>
  );
}

LogoButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
