// import MuiOutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';
import MuiTextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { cloneElement } from 'react';
import * as color from '../constants/colors';

const styles = {
  input: {
    boxSizing: 'border-box',
    height: '3.5rem',
    pt: 1.375,
    pb: 1.25,
  },
  inputNoAdornment: {
    px: 3,
    ml: 0.9375,
  },
  startAdornment: {
    pr: 0.25,
  },
  withStartAdornment: {
    pl: 1.625,
  },
};

const TextField = styled((props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <MuiTextField variant="outlined" {...props} />
))(({ theme }) => ({
  backgroundColor: color.surfaceLight,
  borderRadius: `${theme.shape.borderRadius}px`,
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: color.outlineLight,
    },
    '&.Mui-focused fieldset': {
      borderColor: color.tertiaryLight,
    },
    '&.Mui-error fieldset': {
      borderWidth: '2px',
      borderColor: color.primaryLight,
    },
  },
}));

export default function OutlinedInput(props) {
  const { startAdornment: startAdornmentProp, ...other } = props;
  const startAdornment = startAdornmentProp
    ? cloneElement(startAdornmentProp, { sx: styles.startAdornment })
    : undefined;
  return (
    <TextField
      id="outlined-input"
      inputProps={{
        sx: [styles.input, startAdornment === undefined && styles.inputNoAdornment],
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{ startAdornment }}
      sx={[styles.outlinedInput, startAdornment && styles.withStartAdornment]}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    />
  );
}

OutlinedInput.propTypes = {
  autoFocus: PropTypes.bool,
  startAdornment: PropTypes.node,
};

OutlinedInput.defaultProps = {
  autoFocus: false,
  startAdornment: null,
};
