// Material theme builder input color codes:
//   primary: #de1e1b
//   secondary: #7e7a00
//   tertiary: #008392
//   extended blue: #4aaade
//   extended red: #ef8c85
//   extended orange: #f5b467

export const primary100 = '#ffffff';
export const primary99 = '#fcfcfc';
export const primary98 = '#fff8f6';
export const primary95 = '#ffede9';
export const primary90 = '#ffdad3';
export const primary80 = '#ffb4a8';
export const primary70 = '#ff8978';
export const primary60 = '#ff5446';
export const primary50 = '#e62620';
export const primary40 = '#c00007';
export const primary35 = '#a90004';
export const primary30 = '#940003';
export const primary25 = '#7e0002';
export const primary20 = '#680001';
export const primary10 = '#410001';
export const primary0 = '#000000';

export const secondary100 = '#ffffff';
export const secondary99 = '#fffcec';
export const secondary98 = '#fffcba';
export const secondary95 = '#fbf67a';
export const secondary90 = '#ece76d';
export const secondary80 = '#d0cb54';
export const secondary70 = '#b4af3c';
export const secondary60 = '#989421';
export const secondary50 = '#7e7a00';
export const secondary40 = '#646100';
export const secondary35 = '#585500';
export const secondary30 = '#4c4900';
export const secondary25 = '#3f3d00';
export const secondary20 = '#353200';
export const secondary10 = '#1e1c00';
export const secondary0 = '#000000';

export const tertiary100 = '#ffffff';
export const tertiary99 = '#f4feff';
export const tertiary98 = '#ebfcff';
export const tertiary95 = '#ccf8ff';
export const tertiary90 = '#95f0ff';
export const tertiary80 = '#50d8ec';
export const tertiary70 = '#25bcd0';
export const tertiary60 = '#009fb2';
export const tertiary50 = '#008393';
export const tertiary40 = '#006875';
export const tertiary35 = '#005b67';
export const tertiary30 = '#004f59';
export const tertiary25 = '#00424a';
export const tertiary20 = '#00363e';
export const tertiary10 = '#001f24';
export const tertiary0 = '#000000';

export const neutral100 = '#ffffff';
export const neutral99 = '#fbfdfd';
export const neutral98 = '#f8fafa';
export const neutral95 = '#eff1f1';
export const neutral90 = '#e0e3e3';
export const neutral80 = '#c4c7c7';
export const neutral70 = '#a9acac';
export const neutral60 = '#8e9192';
export const neutral50 = '#747778';
export const neutral40 = '#5c5f60';
export const neutral35 = '#505354';
export const neutral30 = '#444748';
export const neutral25 = '#383c3d';
export const neutral20 = '#2d3132';
export const neutral10 = '#191c1d';
export const neutral0 = '#000000';

export const neutralVariant100 = '#ffffff';
export const neutralVariant99 = '#fffbfe';
export const neutralVariant98 = '#fef7ff';
export const neutralVariant95 = '#f5eefa';
export const neutralVariant90 = '#e7e0ec';
export const neutralVariant80 = '#cac4d0';
export const neutralVariant70 = '#aea9b4';
export const neutralVariant60 = '#938f99';
export const neutralVariant50 = '#79747e';
export const neutralVariant40 = '#605d66';
export const neutralVariant35 = '#54515a';
export const neutralVariant30 = '#c49454f';
export const neutralVariant25 = '#3d3a43';
export const neutralVariant20 = '#322f37';
export const neutralVariant10 = '#1d1a22';
export const neutralVariant0 = '#000000';

export const primaryLight = primary40;
export const onPrimaryLight = primary100;
export const primaryContainerLight = primary90;
export const onPrimaryContainerLight = primary10;
export const secondaryLight = secondary40;
export const onSecondaryLight = secondary100;
export const secondaryContainerLight = secondary90;
export const onSecondaryContainerLight = secondary10;
export const tertiaryLight = tertiary40;
export const onTertiaryLight = tertiary100;
export const tertiaryContainerLight = tertiary90;
export const onTertiaryContainerLight = tertiary10;
export const outlineLight = neutralVariant50;
export const backgroundLight = neutral99;
export const onBackgroundLight = neutral10;
export const surfaceLight = neutral99;
export const onSurfaceLight = neutral10;
export const surfaceVariantLight = neutralVariant90;
export const onSurfaceVariantLight = neutralVariant30;
export const inverseSurfaceLight = neutral20;
export const inverseOnSurfaceLight = neutral95;
export const inversePrimaryLight = primary80;

export const primaryDark = primary80;
export const onPrimaryDark = primary20;
export const primaryContainerDark = primary30;
export const onPrimaryContainerDark = primary90;
export const secondaryDark = secondary95; // Work with (lighter) dark background
export const onSecondaryDark = secondary10; // Work with (lighter) dark background
export const secondaryContainerDark = secondary30;
export const onSecondaryContainerDark = secondary90;
export const tertiaryDark = tertiary80;
export const onTertiaryDark = tertiary20;
export const tertiaryContainerDark = tertiary30;
export const onTertiaryContainerDark = tertiary90;
export const outlineDark = neutral40; // Custom
export const backgroundDark = '#2c2c2c';
export const onBackgroundDark = neutral90;
export const surfaceDark = '#2c2c2c';
export const onSurfaceDark = neutral90;
export const surfaceVariantDark = neutralVariant30;
export const onSurfaceVariantDark = neutralVariant80;
export const inverseSurfaceDark = neutral90;
export const inverseOnSurfaceDark = neutral10;
export const inversePrimaryDark = primary40;

export const extendedBlue100 = '#ffffff';
export const extendedBlue99 = '#fafcff';
export const extendedBlue98 = '#f5faff';
export const extendedBlue95 = '#e3f3ff';
export const extendedBlue90 = '#c4e7ff';
export const extendedBlue80 = '#80cfff';
export const extendedBlue70 = '#51b5eb';
export const extendedBlue60 = '#2c9acf';
export const extendedBlue50 = '#007fb1';
export const extendedBlue40 = '#00658e';
export const extendedBlue35 = '#00587d';
export const extendedBlue30 = '#004c6c';
export const extendedBlue25 = '#004d5c';
export const extendedBlue20 = '#00344c';
export const extendedBlue10 = '#001e2e';
export const extendedBlue0 = '#000000';

export const extendedRed100 = '#ffffff';
export const extendedRed99 = '#fcfcfc';
export const extendedRed98 = '#fff8f6';
export const extendedRed95 = '#ffedea';
export const extendedRed90 = '#ffdad5';
export const extendedRed80 = '#ffb3ab';
export const extendedRed70 = '#fb8b84';
export const extendedRed60 = '#db726b';
export const extendedRed50 = '#bb5953';
export const extendedRed40 = '#9c413d';
export const extendedRed35 = '#8d3632';
export const extendedRed30 = '#7e2b28';
export const extendedRed25 = '#6e1f1d';
export const extendedRed20 = '#5f1414';
export const extendedRed10 = '#410003';
export const extendedRed0 = '#000000';

export const extendedOrange100 = '#ffffff';
export const extendedOrange99 = '#fcfcfc';
export const extendedOrange98 = '#fff8f2';
export const extendedOrange95 = '#ffeedd';
export const extendedOrange90 = '#ffddb7';
export const extendedOrange80 = '#ffb85e';
export const extendedOrange70 = '#e69b38';
export const extendedOrange60 = '#c7811e';
export const extendedOrange50 = '#a96900';
export const extendedOrange40 = '#875200';
export const extendedOrange35 = '#774800';
export const extendedOrange30 = '#673d00';
export const extendedOrange25 = '#573300';
export const extendedOrange20 = '#482900';
export const extendedOrange10 = '#2c1700';
export const extendedOrange0 = '#000000';
