import MuiIconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import * as color from '../constants/colors';

const styles = {
  iconButton: {
    backgroundColor: color.primaryLight,
    color: color.onPrimaryLight,
    '&:hover': {
      backgroundColor: color.primaryLight,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
};

export default function IconButton(props) {
  const { children, sx, ...other } = props;
  return (
    <MuiIconButton
      sx={[styles.iconButton, ...(sx && Array.isArray(sx) ? sx : []), ...(sx && !Array.isArray(sx) ? [sx] : [])]}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {children}
    </MuiIconButton>
  );
}

IconButton.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
};

IconButton.defaultProps = {
  sx: null,
};
