import { keyframes } from '@emotion/react';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import MailIcon from '@mui/icons-material/MailOutlineRounded';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Drawer from '@mui/material/Drawer';
import MuiIconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useState } from 'react';
import * as color from '../constants/colors';
import theme from '../constants/theme';
import { validEmail } from '../utils/email';
import requestConsult from '../utils/firebase';
import Button from './Button';
import IconButton from './IconButton';
import OutlinedInput from './OutlinedInput';

const shakeKeyFrames = keyframes`
  0% { margin-left: 0rem; }
  25% { margin-left: 0.5rem; }
  75% { margin-left: -0.5rem; }
  100% { margin-left: 0rem; }
`;

const styles = {
  dialogBox: {
    backgroundColor: color.backgroundLight,
  },
  dialogTitle: {
    fontWeight: 500,
    letterSpacing: '0.85px',
    lineHeight: '1.3333',
    pt: 3,
    pb: 2,
  },
  dialogSubtitleBox: {
    maxWidth: 310,
  },
  dialogSubtitle: {
    color: color.neutral40,
    letterSpacing: '0.25px',
    lineHeight: 1.625,
  },
  dialogInputShake: {
    animation: `${shakeKeyFrames} 0.15s ease-in-out 0s 4`,
  },
  dialogMailIcon: {
    color: color.inverseSurfaceLight,
    mr: '0.5rem',
    ml: '0.375rem',
  },
  dialogSendButton: {
    height: '3.25rem',
    width: '7.875rem',
  },
  dialogStack: {
    pt: 4,
  },
  dialog: {
    backdropFilter: 'blur(2px) opacity(0)',
    transition: theme.transitions.create('backdrop-filter', {
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  dialogPaper: {
    backgroundColor: color.neutral100,
    border: `1px solid ${color.backgroundLight}`,
    p: 1.5,
    minWidth: 475,
    overflow: 'visible',
  },
  drawerCloseBox: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    top: 0,
    p: 0.25,
  },
  dialogCloseBox: {
    backgroundColor: color.onPrimaryLight,
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    right: -11,
    top: -11,
    borderRadius: '17px',
    p: 0.25,
    boxShadow: theme.shadows[2],
  },
  dialogCloseIcon: {
    height: 15,
    width: 15,
  },
  dialogCloseButton: {
    backgroundColor: color.primaryLight,
    color: color.onPrimaryLight,
    height: 18,
    width: 18,
  },
  dialogOpen: {
    backdropFilter: 'blur(2px) opacity(1)',
  },
};

export default function ContactForm(props) {
  const { mobileView, onClose, title } = props;
  const [error, setError] = useState({
    email: false,
    name: false,
  });
  const [shake, setShake] = useState({
    email: false,
    name: false,
  });
  const [values, setValues] = useState({
    email: '',
    name: '',
  });

  const handleChange = (prop) => (event) => {
    setError({ ...error, [prop]: false });
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = () => {
    if (!(values.name && values.name.length > 0)) {
      setError({ ...error, name: true });
      setShake({ ...shake, name: true });
      return;
    }
    if (!validEmail(values.email)) {
      setError({ ...error, email: true });
      setShake({ ...shake, email: true });
      return;
    }
    setError({ name: false, email: false });
    requestConsult(values.email, values.name);
    onClose();
  };

  const form = (autoFocus) => (
    <Box sx={styles.dialogBox}>
      <DialogTitle sx={styles.dialogTitle}>{title}</DialogTitle>
      <DialogContent>
        <Box sx={styles.dialogSubtitleBox}>
          <Typography sx={styles.dialogSubtitle}>
            Please send us your name and email and we&apos;ll get back to you today.
          </Typography>
        </Box>
        <Stack spacing={3} sx={styles.dialogStack}>
          <OutlinedInput
            autoComplete="name"
            autoFocus={autoFocus}
            error={error.name}
            fullWidth
            id="name"
            onAnimationEnd={() => setShake({ ...shake, name: false })}
            onChange={handleChange('name')}
            placeholder="Your name"
            required
            sx={shake.name && styles.dialogInputShake}
            value={values.name}
          />
          <OutlinedInput
            autoComplete="email"
            error={error.email}
            fullWidth
            id="email"
            onAnimationEnd={() => setShake({ ...shake, email: false })}
            onChange={handleChange('email')}
            placeholder="Email address"
            required
            startAdornment={
              <InputAdornment position="start">
                <MailIcon sx={styles.dialogMailIcon} />
              </InputAdornment>
            }
            sx={shake.email && styles.dialogInputShake}
            type="email"
            value={values.email}
          />
          <Button onClick={handleSubmit} size="large" sx={styles.dialogSendButton} variant="contained">
            SEND
          </Button>
        </Stack>
      </DialogContent>
    </Box>
  );

  return mobileView ? (
    <Drawer anchor="bottom" open onClose={onClose}>
      <Box sx={styles.drawerCloseBox}>
        <MuiIconButton aria-label="Close" onClick={onClose}>
          <CloseIcon />
        </MuiIconButton>
      </Box>
      {form(false)}
    </Drawer>
  ) : (
    <Dialog
      BackdropProps={{ invisible: true }}
      keepMounted
      onClose={onClose}
      open
      PaperProps={{ square: true, sx: styles.dialogPaper }}
      sx={[styles.dialog, styles.dialogOpen]}
      TransitionProps={{
        timeout: 500,
      }}
    >
      <Box sx={styles.dialogCloseBox}>
        <IconButton aria-label="Close" onClick={onClose} sx={styles.dialogCloseButton}>
          <CloseRoundedIcon sx={styles.dialogCloseIcon} />
        </IconButton>
      </Box>
      {form(true)}
    </Dialog>
  );
}

ContactForm.propTypes = {
  mobileView: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
};

ContactForm.defaultProps = {
  title: '',
};
