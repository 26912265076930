import { useEffect, useState } from 'react';

const useIntersection = (element, rootMargin) => {
  const [isVisible, setState] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          if (!isVisible) {
            setState(entry.isIntersecting);
            observer.unobserve(element.current);
          }
        }
      },
      { rootMargin },
    );
    if (element.current) {
      observer.observe(element.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => observer.unobserve(element.current);
  });
  return isVisible;
};

export default useIntersection;
